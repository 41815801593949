@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap");

$grey: #cccccc;
$grey_dark: #333333;
$black: #000000;
$wite: #ffffff;
$blue: #008bcf;

body {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: $white;
  color: $black;
}

a {
  text-decoration: none;
  transition: 0.3s;

  &:hover,
  &:active {
    opacity: 0.7;
  }
}

main {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    color: $black;

    &:visited,
    &:hover,
    &:active {
      color: $black;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .section {
    &-logo {
      margin-bottom: 80px;
      padding: 32px 0;
      background-color: $white;

      &-image {
        width: 420px;
      }
    }

    &-download {
      padding: 16px 0;
      &-text {
        font-size: 1.2rem;
        line-height: 3rem;
        margin-bottom: 0;
      }
    }

    &-contact {
      &-text {
        font-size: 14px;
        line-height: 33px;
        margin-bottom: 24px;
      }

      &-link {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media(min-width: 576px) {
            flex-direction: row;
        }

        &-blue {
          color: $blue;

          &:visited,
          &:hover,
          &:active {
            color: $blue;
          }
        }
      }

      &-icon {
        max-width: 1.5rem;
      }
    }
  }
}

footer {
  color: $white;
  background-color: $blue;
  font-size: 14px;
  padding: 8px 0;

  p {
    margin-bottom: 0;
  }

  a {
    color: $white;

    &:visited,
    &:hover,
    &:active {
      color: $white;
    }
  }
}
